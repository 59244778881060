import { useLazyQuery } from '@apollo/client'
import classNames from 'classnames'
import moment from 'moment'
import React, { memo, useEffect, useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import Moment from 'react-moment'
import IconViewEmailByAMS from '../../../../../assets/images/icon-email.svg'
import Loading from '../../../../../components/loading'
import { notify } from '../../../../../components/toastify'
import { styleJsonPacket } from '../../../../../constants/common'
import { MESSAGES, STATUS } from '../../../../../constants/messages'
import { GET_MESSAGE_MAILS } from '../../../../../queries/adminPortal'
import ViewDetailContentItemDialog from '../../share/view-detail-content-log'
import { tableHeaderReceivedEmailHistoryTable } from './headers'
import DateFilterPicker from '../../job-data-table/components/dateFilterPicker'
import SearchInput from '../../job-data-table/components/searchInput'

interface Props {
  setIsRefetchList: (refetchUser: boolean) => void
  isRefetchList: boolean
}

interface IMessageMailItem {
  messageId: string
  applicationNumber: string
  carrierBondNumber: string
  subject: string
  content: string
  receiver: string
  createdAt: string
  isProcessed: boolean
}
interface IReceivedEmailFilterItem {
  dateFrom?: string
  dateTo?: string
  skip: number
  take: number
  searchValue?: string
}

const ReceivedEmailHistoryTable: React.FC<Props> = () => {
  const [hasMoreEmailData, setHasMoreEmailData] = useState<boolean>(true)
  const [isOpenModalViewDetailEmail, setIsOpenModalViewDetailJob] = useState<boolean>(false)
  const [currentMessageMailItemSelected, setCurrentMessageMailItemSelected] =
    useState<IMessageMailItem>()
  const [listMessageMail, setListMessageMail] = useState<IMessageMailItem[]>([])
  const [searchObject, setSearchObject] = useState<IReceivedEmailFilterItem>({
    searchValue: '',
    skip: listMessageMail.length,
    take: 20,
  })
  const prevSearchObjectRef = useRef({})
  const [minDateFilter, setMinDateFilter] = useState<Date>()
  const [maxDateFilter, setMaxDateFilter] = useState<Date>()
  const [isFocusSearchJobIdOrEmail, setIsFocusSearchJobIdOrEmail] = useState<boolean>(false)
  const [
    getMessageMail,
    { loading: getMessageMailsLoading, fetchMore: fetchMoreForGetMessageMail },
  ] = useLazyQuery(GET_MESSAGE_MAILS)

  const handleGetData = async (searchObject: IReceivedEmailFilterItem) => {
    const response = await getMessageMail({ variables: searchObject, fetchPolicy: 'no-cache' })
    const data = response.data?.getMessageMails
    if (data) {
      setListMessageMail(data)
    }
  }

  const fetchMoreDataForMessageEmailHistory = async () => {
    try {
      const response = await fetchMoreForGetMessageMail({
        variables: {
          filter: {
            ...searchObject,
            skip: listMessageMail.length,
            take: 20,
          },
        },
      })
      const data = response.data.getMessageMails
      if (data.length > 0) {
        setHasMoreEmailData(data.length === 20)
        const dataState = [...listMessageMail, ...data]
        setTimeout(() => {
          setListMessageMail(dataState)
        }, 1000)
      } else {
        setHasMoreEmailData(false)
        return
      }
    } catch (error) {
      notify(MESSAGES.ERROR.E_TRY_AGAIN, STATUS.ERROR)
    }
  }

  const handleCloseEmailInfoDialog = () => {
    setIsOpenModalViewDetailJob(false)
  }

  const onHandleClickViewDetailJobAction = () => {
    setIsOpenModalViewDetailJob(true)
  }
  const startDateChange = (value: Date) => {
    setMinDateFilter(value)
    const dateFrom = value ? moment(value).format('YYYY-MM-DD') : ''
    setSearchObject((state) => {
      return { ...state, dateFrom, take: 20, skip: 0 }
    })
  }
  const endDateChange = (value: Date) => {
    setMaxDateFilter(value)
    const dateTo = value ? moment(value).format('YYYY-MM-DD') : ''
    setSearchObject((state) => {
      return { ...state, dateTo, take: 20, skip: 0 }
    })
  }
  const handleSearchBox = (value: string) => {
    setSearchObject((state) => {
      return { ...state, searchValue: value.trim(), take: 20, skip: 0 }
    })
  }



  useEffect(() => {
    if (
      searchObject &&
      JSON.stringify(searchObject) !== JSON.stringify(prevSearchObjectRef.current)
    ) {
      setHasMoreEmailData(true)
      handleGetData(searchObject)
    }
    prevSearchObjectRef.current = searchObject
  }, [searchObject])

  return (
    <div>
      <div className="flex justify-between mb-3">
        <p className="pt-2 text-headline5A text-neutral-1">Received Email History</p>
      </div>
      <div className="flex w-full mb-5 grid grid-cols-3 gap-2">
        <DateFilterPicker setCallBackDateChange={startDateChange} maxDate={maxDateFilter} />
        <DateFilterPicker setCallBackDateChange={endDateChange} minDate={minDateFilter} />
        <div
          className={classNames('rounded-xl border-[2px]  w-full', {
            'border-neutral-3': isFocusSearchJobIdOrEmail,
            'border-neutral-5': !isFocusSearchJobIdOrEmail,
          })}
        >
          <SearchInput
            classInput="border-0 w-full"
            setIsFocus={setIsFocusSearchJobIdOrEmail}
            handleSearch={handleSearchBox}
            placehoderText="Search subject/ applicationNumber/ carrierBondNumber"
            isHandleSearch={false}
          />
        </div>
      </div>
      <table className="w-full text-left">
        <thead className="bg-primary-shade4 text-body1 text-neutral-2 md:pr-4">
          <tr>
            {tableHeaderReceivedEmailHistoryTable.map((item: any, idx) => (
              <th
                key={idx}
                className={`font-semibold py-5 ${
                  idx === tableHeaderReceivedEmailHistoryTable.length - 1 ? `` : ` pl-6`
                } ${item.styles}`}
              >
                <div className={`flex`}>
                  <span className={`${item.isOneLineRes && 'lg:mt-[-3.5px]'}`}>{item.name}</span>
                  <div className={`${item.isOneLineRes && 'lg:mt-[-3.5px]'}`}></div>
                </div>
              </th>
            ))}
          </tr>
        </thead>
      </table>
      {getMessageMailsLoading && <Loading className="relative py-6" height={30} width={30} />}

      {listMessageMail?.length === 0 && !getMessageMailsLoading ? (
        <p className="py-6 text-center text-body1 text-neutral-4">No data available.</p>
      ) : (
        Boolean(
          listMessageMail !== null && listMessageMail?.length > 0 && !getMessageMailsLoading,
        ) && (
          <InfiniteScroll
            dataLength={listMessageMail?.length}
            next={fetchMoreDataForMessageEmailHistory}
            hasMore={hasMoreEmailData}
            height={'max-content'}
            style={{ maxHeight: 980 }}
            loader={''}
            endMessage={''}
            className="cus-scrollbar"
          >
            {listMessageMail?.map((messageMail: IMessageMailItem, index: number) => (
              <table key={index} className="cursor-pointer w-full">
                <tbody>
                  <tr className="h-[72px] w-full hover:bg-neutral-7 pt-5">
                    <td
                      className={` ${tableHeaderReceivedEmailHistoryTable[0].rowWid} py-5 break-all pl-6 md:pl-1 align-top md:pl-6`}
                    >
                      <span>{messageMail.applicationNumber || 'NA'}</span>
                    </td>
                    <td
                      className={`${
                        tableHeaderReceivedEmailHistoryTable[1].rowWid
                      } py-5 break-all pl-6 md:pl-3 align-top ${'md:pl-[20px]'}`}
                    >
                      <span>
                        {
                          <Moment format="yyyy-MM-DD HH:mm">
                            {moment.utc(messageMail.createdAt ?? '')}
                          </Moment>
                        }
                      </span>
                    </td>

                    <td
                      className={`${tableHeaderReceivedEmailHistoryTable[2].rowWid} py-5 pl-8 break-all align-top`}
                    >
                      <span> {messageMail?.receiver}</span>
                    </td>
                    <td
                      className={`${tableHeaderReceivedEmailHistoryTable[3].rowWid} py-5 break-all pl-6 md:pl-1 align-top md:pl-6`}
                    >
                      <span>{messageMail.subject}</span>
                    </td>
                    <td
                      className={`${tableHeaderReceivedEmailHistoryTable[4].rowWid} lg:pl-8 pl-1 md:${tableHeaderReceivedEmailHistoryTable[3].rowWid} align-top`}
                    >
                      <div className="relative w-full break-all ">
                        <div className="h-[86px]">
                          <p
                            dangerouslySetInnerHTML={{ __html: messageMail.content }}
                            style={{ ...styleJsonPacket }}
                            className={`break-all lg:pl-2 pt-[19px] pl-6 pr-6`}
                          ></p>
                          <button
                            title="View Email"
                            onClick={(e) => {
                              e.stopPropagation()
                              onHandleClickViewDetailJobAction()
                              setCurrentMessageMailItemSelected(messageMail)
                            }}
                          >
                            <img
                              src={IconViewEmailByAMS}
                              alt="Icon-Hover-Copy"
                              className="absolute top-[24px] right-0"
                            />
                          </button>
                        </div>
                      </div>
                    </td>
                    <td
                      className={`${tableHeaderReceivedEmailHistoryTable[5].rowWid} py-5 break-all pl-6 md:pl-1 align-top md:pl-6`}
                    >
                      <span>{messageMail.carrierBondNumber || 'NA'}</span>
                    </td>
                    <td
                      className={`${tableHeaderReceivedEmailHistoryTable[6].rowWid} py-5 pl-2 break-all align-top`}
                    >
                      <span> {messageMail?.isProcessed?.toString().toUpperCase()}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            ))}
          </InfiniteScroll>
        )
      )}

      {isOpenModalViewDetailEmail && (
        <ViewDetailContentItemDialog
          key={currentMessageMailItemSelected?.messageId}
          modalIsOpen={true}
          title="View Email Detail"
          handleCloseDialog={handleCloseEmailInfoDialog}
          contentHtml={currentMessageMailItemSelected?.content || ''}
        />
      )}
    </div>
  )
}
export default memo(ReceivedEmailHistoryTable)
